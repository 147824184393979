import React, { useRef } from "react";
import { graphql } from "gatsby";
import { getSrc, IGatsbyImageData } from "gatsby-plugin-image";

import Layout from "../components/layout";

const PlainPage = ({ data }: any) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { markdownRemark } = data;
  const {
    html,
    frontmatter: { title, description, video },
  }: { html: string; frontmatter: { title: string; description: string; video: any } } =
    markdownRemark;

  return (
    <Layout title={title} metaDescription={description} isMarketingNavDisplayed={false}>
      {video && (
        <div
          id="tutorial"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "2rem",
            scrollMarginTop: "4rem",
            background: "black",
          }}
        >
          <video
            poster={getSrc(video.videoScreenshots[0] as IGatsbyImageData)}
            style={{
              maxWidth: 400,
              maxHeight: "80vh",
            }}
            controls
          >
            <source src={video.videoH264.path} />
          </video>
        </div>
      )}
      <div ref={contentRef} dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
};

// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        description
        video {
          videoH264 {
            path
          }
          videoScreenshots {
            childImageSharp {
              gatsbyImageData(width: 400, layout: FIXED)
            }
          }
        }
      }
    }
  }
`;

export default PlainPage;
